.body{
    background: #0c192c;
    font-family:  sans-serif;
}
.container {
    width : 100% ;
    min-height : 100vh;
    overflow: hidden;
    display : flex; 
    justify-content: center;
    align-items:  center ;
    flex-direction:  column;
}
.text {
    color : white ;
    margin-bottom:  25px ;
    font-size : 20px ;
    letter-spacing:  2px;
}
.anim{
    position :relative;
    display : flex;
}

.anim span{
    position:relative;
    width: 30px;
    height:  30px;
    background: #4fc3dc;
    margin : 0 4px;
    
    border-radius: 50%;
    box-shadow : 0 0 0 10 px #4fc3dc44, 0 0 50px #4fc3dc, 0 0 100px #4fc3dc;
    animation:  animate 15s linear infinite;
    animation-duration: calc(10s/var(random(30)));
}
.anim span:nth-child(even){
    background: #ff2d75;
    box-shadow : 0 0 0 10 px #ff2d75, 0 0 50px #ff2d75, 0 0 100px #ff2d75;
   
}

@keyframes animate{
     0%{
        transform : translateY(100vh) scale(0);
     }
     100%{
        transform: translateY(-10vh) scale(1);
     }
}