html,
body,
#root{
  position : fixed;
  top:0;
  left: 0;
  width : 100%;
  height : 100%;
  overflow : hidden;

}
canvas{
  touch-action:  none;
}